<template>
  <span class="ch-messages-icon">
    <i class="ch-icons-message ch-messages-icon__icon" v-router-link="{'name': 'Messages'}"/>
    <span v-if="hasUnseenMessages"  class="ch-new-content-indicator">  </span>
  </span>
</template>

<script>
import ListConversations from '@/gql/conversations/ListConversations.gql';
import ConversationsUpdatedSubscription from '@/gql/conversations/ConversationsUpdatedSubscription.gql'; // Import the conversations subscription
import $auth from '@/services/auth';

export default {
  apollo: {
    conversations: {
      query: ListConversations,
      variables() {
        return {
          userId: $auth.getUserId(),
        };
      },
    },
  },
  mounted() {
    // subscribe to ConversationsUpdatedSubscription
    this.$apollo.queries.conversations.subscribeToMore({
      document: ConversationsUpdatedSubscription,
      variables: {
        userId: $auth.getUserId(),
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedConversation = subscriptionData.data.conversationsUpdated;
        this.$apollo.queries.conversations.refetch();
      },
    });

    // retain the event listener for 'resume'
    document.addEventListener('resume', () => {
      this.$apollo.queries.conversations.refetch();
    });
    setInterval(() => {
      this.$apollo.queries.conversations.refetch();
    }, 60 * 1000);
  },
  computed: {
    hasUnseenMessages() {
      if (this.conversations) {
        let newMsg = false;
        const filteredConversations = this.conversations.filter((t) => t.otherParty);
        if (filteredConversations && filteredConversations.length) {
          filteredConversations.forEach((data) => {
            if (data.latestMessage && data.latestMessage.senderId == data.otherParty.id && new Date(data.latestMessage.msgCreateTime).getTime() > localStorage.lastSeenMessageTime) {
              newMsg = true;
            }
          });
        }
        return newMsg;
      }
    },
  },
};
</script>

<style scoped>
.ch-messages-icon{
  box-sizing: border-box;
  position: relative;
}
.ch-messages-icon__icon {
  font-size: 26px;
  color: var(--cs-gray-05);
  padding: 5px;
  line-height: 24px;
}
.ch-new-content-indicator{
  position: absolute;
  top: 0px;
  right: 3px;
  width: 10px;
  height: 10px;
  background: var(--cs-primary-base);
  border-radius: 25px;
}
</style>
