<template>
  <ion-page>
    <ion-header>
      <div class="ch-message">
        <div class="ch-message__header">
          <app-category-header
            title="Messages"
            create
            @create="openNewMessageModal"
          />
        </div>
        <div class="ch-message__nav">
          <app-tabs
            v-model="selectedOption"
            :tabs="messageFilters"
            @click="optionChanged"
          />
        </div>
      </div>
    </ion-header>

    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div v-show="selectedKey === 'friends'">
        <template v-if="friendsList">
          <app-conversation-list
            :list="friendsList"
            description="You have not any messages from friends"
          />
        </template>
      </div>

      <div v-show="selectedKey === 'others'">
        <div class="other-conversations-toggle">
          <app-toggle
            v-model="showOtherMessages"
            label="Allow non-friends to message me"
            @click="toggleAllowOtherMessages"
          />
        </div>
        <template v-if="othersList && showOtherMessages">
          <app-conversation-list
            :list="othersList"
            description="You have not any messages from others"
          />
        </template>
      </div>

      <app-infinite-loader
        :paused="$apollo.queries.conversations.loading"
        :finished="!mayHaveMore"
        @more="loadMore()"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
// Components
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppToggle from '@/components/general/Toggle.vue';
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';
import AppConversationList from '@/components/conversations/ConversationList.vue';
// GQL
import ListConversations from '@/gql/conversations/ListConversations.gql';
import AllowOtherMessages from '@/gql/conversations/AllowOtherMessages.gql';
import GetProfile from '@/gql/general/GetProfile.gql';
// Subscriptions
import NewMessageSubscription from "@/gql/conversations/NewMessageSubscription.gql";

const messageFilters = [
  {
    label: 'Friends',
    key: 'friends',
  },
  {
    label: 'Others',
    key: 'others',
  },
];

export default {
  components: {
    AppCategoryHeader,
    AppTabs,
    AppPullToRefresh,
    AppInfiniteLoader,
    AppToggle,
    AppConversationList,
  },
  apollo: {
    conversations: {
      query: ListConversations,
      variables() {
        return {
          userId: $auth.getUserId(),
        };
      },
    },
    user: {
      query: GetProfile,
      variables() {
        return {
          id: $auth.getUserId(),
        };
      },
    },
  },
  data() {
    return {
      showMsgModal: false,
      selectedOption: 0,
      messageFilters,
      mayHaveMore: true,
      showOtherMessages: false,
      subscriptionHandles: [], // Add this property
    };
  },
  computed: {
    selectedKey() {
      const selectedView = this.messageFilters[this.selectedOption].key;
      return selectedView;
    },
    friendsList() {
      return (
        this.conversations &&
        this.conversations.filter(
          (c) =>
            c.otherParty &&
            c.otherParty.myRelationship &&
            c.otherParty.myRelationship.isFriend &&
            !c.otherParty.myRelationship.isBlocked
        )
      );
    },
    othersList() {
      return (
        this.conversations &&
        this.conversations.filter(
          (c) =>
            c.otherParty &&
            (!c.otherParty.myRelationship ||
              !c.otherParty.myRelationship.isFriend) &&
            !c.otherParty.myRelationship.isBlocked
        )
      );
    },
  },
  watch: {
    user() {
      this.showOtherMessages = this.user.allowUnsolicitedMessages;
    },
    conversations: {
      handler(conversations) {
        if (conversations) {
          this.unsubscribeFromAllConversations();
          this.subscribeToAllConversations(conversations);
        }
      },
      deep: true,
    },
  },
  mounted() {
    localStorage.lastSeenMessageTime = Date.now();
    this.$apollo.queries.conversations.refetch();
    setInterval(() => {
      this.$apollo.queries.conversations.refetch();
    }, 1000 * 10);
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.conversations.refetch();
      $event.target.complete();
    },
    async optionChanged() {
      await this.$apollo.queries.conversations.refetch();
    },
    openNewMessageModal() {
      $bus.$emit('new-message-modal', {});
    },
    async toggleAllowOtherMessages() {
      await this.$apollo.mutate({
        mutation: AllowOtherMessages,
        variables: {
          allowUnsolicitedMessages: !this.showOtherMessages,
        },
      });
      this.$apollo.queries.user.refetch();
    },
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const offset = this.conversations.length;
        const before =
          this.conversations[this.conversations.length - 1].msgCreateTime;
        this.$apollo.queries.conversations.fetchMore({
          variables: {
            userId: $auth.getUserId(),
            offset,
            before,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.conversations.length;
            return {
              conversations: [
                ...previousResult.conversations,
                ...fetchMoreResult.conversations,
              ],
            };
          },
        });
      }
    },
    subscribeToAllConversations(conversations) {
      conversations.forEach((conversation) => {
        const conversationId = conversation.id;
        const subscription = this.$apollo.subscribe({
          query: NewMessageSubscription,
          variables: {
            conversationId,
          },
        }).subscribe({
          next: ({ data }) => {
            const newMessage = data.messageAdded;
            const conversationIndex = this.conversations.findIndex(
              (c) => c.id === conversationId
            );
            if (conversationIndex !== -1) {
              this.conversations[conversationIndex].latestMessage =
                newMessage;
            }
          },
          error(err) {
            console.error(`Error in NewMessageSubscription: ${err}`);
          },
        });

        // Store the subscription handle
        this.subscriptionHandles.push({
          conversationId,
          subscription,
        });
      });
    },
    unsubscribeFromAllConversations() {
      if (this.conversations) {
        this.conversations.forEach((conversation) => {
          const conversationId = conversation.id;

          // Find the subscription handle for the conversation
          const subscriptionHandle = this.subscriptionHandles.find(
            (handle) => handle.conversationId === conversationId
          );

          // Unsubscribe using the found handle
          if (subscriptionHandle) {
            subscriptionHandle.subscription.unsubscribe();
          }
        });
      }
    },
  },
};
</script>

<style>
.other-conversations-toggle {
  padding: 10px;
}
</style>
